import React from "react";
export const SteamStore = () => {
  return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.822216 25.8341C3.32139 34.0343 10.9524 40 19.9785 40C31.0359 40 40 31.0455 40 20C40 8.95434 31.0359 0 19.9785 0C9.36782 0 0.685714 8.24532 0 18.6712C0.00792718 18.6845 0.0158248 18.6978 0.0236926 18.711C0.0251324 18.6828 0.0260078 18.6682 0.0260078 18.6682L10.7294 23.1053C11.0206 22.9125 11.3309 22.7455 11.6621 22.6094C12.4134 22.2978 13.2057 22.1583 13.9953 22.1945L18.8697 15.064C18.868 15.0323 18.868 14.9986 18.868 14.9648C18.868 10.7567 22.262 7.33333 26.434 7.33333C30.6077 7.33333 34 10.7567 34 14.9648C34 19.1727 30.6077 22.5991 26.434 22.5991C26.3776 22.5991 26.3194 22.5974 26.263 22.5957L19.2886 27.6148C19.3091 28.3896 19.1672 29.1662 18.8629 29.9066C17.6933 32.739 14.4517 34.0872 11.6402 32.9043C9.92182 32.1828 8.75709 30.6831 8.37716 28.9733L0.822216 25.8341Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6985 29.4141C16.7933 31.6026 14.2959 32.6408 12.1251 31.73C11.1237 31.3099 10.3676 30.5402 9.9314 29.6225L12.3944 30.6504C13.9953 31.3219 15.832 30.5574 16.4975 28.9458C17.165 27.3324 16.4077 25.4797 14.8077 24.8082L12.2616 23.7458C13.244 23.3705 14.3609 23.3567 15.4044 23.7941C16.4565 24.2348 17.2727 25.0648 17.7053 26.1254C18.1379 27.1861 18.1362 28.3569 17.6985 29.4141Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.434 20.0508C23.6555 20.0508 21.3934 17.7694 21.3934 14.9648C21.3934 12.1626 23.6555 9.88044 26.434 9.88044C29.2142 9.88044 31.4763 12.1626 31.4763 14.9648C31.4763 17.7694 29.2142 20.0508 26.434 20.0508ZM22.657 14.9571C22.657 17.067 24.3531 18.7766 26.4425 18.7766C28.5337 18.7766 30.2298 17.067 30.2298 14.9571C30.2298 12.8473 28.5337 11.1362 26.4425 11.1362C24.3531 11.1362 22.657 12.8473 22.657 14.9571Z" fill="white"/>
      </svg>
  );
};
